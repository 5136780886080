<div id="volunteer">
  <div class="top-background">
    <!-- <img src="/assets/images/volunteer/volunteer1.jpg" alt=""> -->
    <!-- <img src="/assets/images/volunteer/volunteer2.jpg" alt=""> -->
    <!-- <img src="/assets/images/volunteer/volunteer3.jpg" alt=""> -->
  </div>
  <div class="header-container">
    <h1 class="header-title">VOLUNTEER</h1>
    <h2 class="sub">“The measure of life is not its duration but its donation.”</h2>
  </div>
  <div class="info-container">
    <div class="text">
      <p>
        There are ample opportunities for internship on many levels. Academic credit may be offered.
      </p>
      <p>
        University/ college clubs and orgs. are all encouraged to take part and share the culture and beauty of Haiti by promoting Haiti as a future destination spot for Spring Break.
      </p>
      <h3 class="title">Intern & Volunteer  (@SBIH) opportunities include:</h3>
      <p>
        Making calls, assisting in operating our social networks: facebook, twitter, SBIH.com website, writers and bloggers for the website, organizers, leaders, realists, soldiers, people with heart and brain.
      </p>
    </div>
    <div class="img-wrap">
      <img src="/assets/images/volunteer/volunteer1.jpg" alt="">
    </div>
  </div>
  <div class="contact-wrap">
    <h3 class="title">Membership Application available</h3>
    <button mat-button routerLink="/contact" class="contact-btn" disableRipple="true">Contact us</button>
  </div>
</div>
