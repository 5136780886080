<div id="about">
  <div class="top-background">
    <img src="/assets/images/about/jacmel.jpg" alt="">
  </div>
  <div class="header-container">
    <h1 class="header-title">ABOUT US</h1>
    <h2 class="sub">Spring Break in Haiti is open to all walks of life</h2>
  </div>
  <section class="info-container">
    <div class="top-section">
      <div class="image-left">
        <img src="/assets/images/about/mission.jpg" alt="">
      </div>
      <div class="text-right">
        <h3 class="title">OUR MISSION</h3>
        <p>
          <strong>Spring Break In Haiti</strong> founded in 2007, promotes Haiti as a major tourism destination for the 21st century. 
          International collaboration is key to building the economy; 
          an international community that can re-establish the tourism industry as well as national infrastructure.
        </p>
      </div>
    </div>
    <div class="bottom-section">
      <div class="text-left">
        <h3 class="title">PURPOSE</h3>
        <p>
          The purpose of Spring Break In Haiti is to give participants the opportunity to become in tune with the many facets of the Haitian culture. 
          It is an opportunity for those who are looking to spend their spring break doing something educational, enjoyable and meaningful.
        </p>
        <p>
          Spring Break In Haiti is open to all walks of life: backpackers, families, humanitarians, professionals, students and all friends of Haiti. 
          Participants will tour the major cities and their surrounding areas; enjoy the ecological and historical landmarks, observe and participate in humanitarian services, establish foreign relations by interacting with community representatives, local university students, and institutional officials. Seminars and workshops will be conducted focusing on cultural, educational, health, and social issues.
        </p>
        <p>
          First priority is given to high school seniors and college/university students during their spring semester recess.
        </p>
      </div>
      <span class="image-wrap">
        <img src="/assets/images/about/rectstove.jpg" alt="">
        <img src="/assets/images/about/us.jpg" alt="">
      </span>
    </div>
  </section>
  <section class="staff-section">
    <h2>Meet the Team</h2>
    <div class="staff-container">
      <div class="staff-box" *ngFor="let staff of bioData">
        <img [src]="staff.photoUrl" class="head-shot" alt="">
        <h3 class="name">{{staff.name}}</h3>
        <h4 class="position">{{staff.title}}</h4>
        <p class="bio">
          <span *ngIf="staff.showMore && staff.shortDesc">
            {{staff.shortDesc}}
            <span class="read" (click)="staff.showMore = false">Read More</span>
          </span>
          <span *ngIf="!staff.showMore && staff.shortDesc">
            {{staff.description}}
            <span class="read" (click)="staff.showMore = true">Read Less</span>
          </span>
          </p>
      </div>
    </div>
  </section>
</div>