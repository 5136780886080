import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserApiService } from '../services/user-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent implements OnInit, OnDestroy {
  destinations: any[] = [];
  destination_id: number;
  destination: any;
  isDetail: boolean = false;
  isDetail1: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _backendService: UserApiService, private activedRoute: ActivatedRoute, private router: Router) {
    
    this.destination_id = +this.activedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this._backendService.getDestinations().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.destinations = data.destinations;
        console.log(this.destinations);
      }, (error: any) => {
          let msg = error.message ? error.message : error;
          console.log(msg);
      }, () => {
       
      });
    if (this.destination_id > 0) {
      this.getDestination();
    }

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToLink(id): void {
    this.destination_id = id;
    this.getDestination();
  }

  getDestination(): void {
    this._backendService.getDestinationById(this.destination_id).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.destination = data.destination;
        //console.log(this.destination);
      }, (error: any) => {
        let msg = error.message ? error.message : error;
        console.log(msg);
      }, () => {

      });
  }

  getImage(img): string {
    return '../../assets/images/destinations/' + img;
  }

  showDetail(): void {
    this.isDetail = true;
    console.log(this.isDetail);
  }

  closeDetail(): void {
    this.isDetail = false;
  }
  showDetail1(): void {
    this.isDetail1 = true;
  }

  closeDetail1(): void {
    this.isDetail1 = false;
  }

  returnBack(): void {
    this.destination_id = 0;
  }
}


