import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener("window:beforeunload", ["$event"])
  clearLocalStorage(event) {
    localStorage.clear();
  }

  title = 'spring-break-in-haiti';

  constructor(private translate: TranslateService,) {
    let userLang: string = navigator.language;
    if (!userLang) {
      userLang = 'en';
    }

    if (userLang.indexOf('-') > -1) {
      userLang = userLang.substring(0, 2);
    }
    translate.setDefaultLang(userLang);
    translate.use(userLang);

   // _userService.setLocale(userLang);
  }
}
