import { Component, OnInit } from '@angular/core';
import { UserApiService } from '../services/user-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  packages: any[] = [];
  content: any = {};
  current_package: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _backendService: UserApiService) { window.scrollTo(0, 0); }

  ngOnInit(): void {
    this._backendService.getPackages().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.content = data.introduction;
        this.packages = data.tours;
        console.log(this.packages);
      }, (error: any) => {
        let msg = error.message ? error.message : error;
        console.log(msg);
      }, () => {

      });
  }

  showDetail(item): void {
    this.current_package = item;
  }

  closeDetail(): void {
    this.current_package = null;
  }

  getImage(img: string): string {
    return '../../assets/images/packages/' + img;
  }

}
