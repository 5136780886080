<div id="package">
  <div class="top-background">
    <img src="/assets/images/packages/20160424_130202.jpg" alt="" />
  </div>

  <div class="header-container">
    <h1 class="header-title">PACKAGES</h1>
    <h2 class="sub">
      {{content.line1}}<br />
      {{content.line2}}<br />
      {{content.line3}}<br />
      {{content.line4}}<br />
    </h2>
  </div>

  <section class="info-container">
    <div class="top-section">
      <div class="image-left">
        <img src="/assets/images/packages/20160424_164134.jpg" alt="">
      </div>

      <div class="text-right">
        <h3 class="title">{{content.line5}}</h3>
        <div class="benefit-box">
          <div class="box1">
            <ul class="dashed">
              <li *ngFor="let type of content.trip_types">{{type.type}}</li>
            </ul>
          </div>
          <div class="box2">
            <ul>
              <li *ngFor="let benefit of content.benefits">{{benefit.name}}</li>
            </ul>
          </div>
        </div>
        <h3 class="title">
          {{content.line6}}<br />
          {{content.line7}}
        </h3>
      </div>
    </div>
    <div class="bottom-section">

      <div class="container">
        <p class="h4">ALL INCLUSIVE PACKAGES ARE:</p>

        <ul class="main">
          <li class="item" *ngFor="let item of packages">
            <div class="picture-container">
              <div class="picture-item" [ngStyle]="{'background-image': 'url(../../assets/images/packages/' + item.image +')','background-position':'center center','background-size':'cover','background-repeat': 'no-repeat'}">

              </div>
            </div>
            <div class="desc-container">
              <p class="desc-title">
                {{item.destination}}
              </p>
              <h5>{{item.city}}</h5>
              <div *ngIf="item.packages.length>0; else tbd">
                <div class="package-box" *ngFor="let pack of item.packages">
                  <div class="package-title">
                    {{pack.package}}:
                  </div>
                  <div class="package-content">
                    <ul>
                      <li *ngFor="let type of pack.types">{{type.type}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <ng-template #tbd>
                <div>TBD</div>
              </ng-template>
            </div>

            <div class="link-container">
              <a [routerLink]="" class="link-item" (click)="showDetail(item)">
                <mat-icon class="white">play_circle_outline</mat-icon>
                <mat-icon class="black">play_circle</mat-icon>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- Modal -->
  <div class="overall-detail1" *ngIf="current_package!=null">
    <div class="modal-container">
      <a [routerLink]="" class="detail-close" (click)="closeDetail()">
        <mat-icon>close</mat-icon>
      </a>
      <div class="package-detail">
        <div class="image-item">
          <img [src]="getImage(current_package.image)" class="to-see-card" />
        </div>

        <div class="desc-container">
          <p class="desc-title">
            {{current_package.destination}}
          </p>
          <h5>{{current_package.city}}</h5>
          <div *ngIf="current_package.packages.length>0; else tbd">
            <div class="package-box" *ngFor="let pack of current_package.packages">
              <div class="package-title">
                {{pack.package}}:
              </div>
              <div class="package-content">
                <ul>
                  <li *ngFor="let type of pack.types">{{type.type}}</li>
                </ul>
              </div>
            </div>
          </div>
          <ng-template #tbd>
            <div>TBD</div>
          </ng-template>
        </div>
      </div>
      
    </div>
  </div>

  </div>
