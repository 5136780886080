import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BioData } from './about';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(
    private http: HttpClient
  ) { }

  getBioData(): Observable<BioData[]> {
    const url = "/assets/apis/about-bio.json";
    return this.http.get<{data: BioData[]}>(url).pipe(
      map (data => {
        return data.data;
      }),
      catchError( error => throwError(error))
    );
  }
}
