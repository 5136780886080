<div class="programs">
  <h1 class="page-title">PROGRAMS</h1>
  <section class="text-section academic-overview">
    <div class="img-wrap">
      <img src="/assets/images/programs/program4.jpg" alt="">
    </div>
    <div class="text-wrap">
      <h3 class="title">Academic Overview</h3>
      <p>
        The Ghanaian word Sankofa means "to return and fetch that which you have forgotten in order to move forward."
        <br><br>One of the purposes of this course is to encourage students to investigate the history and current events of Ghana, Africa and Mississippi in order to better understand the deeprooted history of African Americans. The instructor will use a multi-discipline approach, pulling from Political Science, Psychology and History. Hence, one of the components will focus on challenging the various negative stereotypes that have been manufactured about the continent of Africa and its
        descendants.
        <br><br>Students will explore such concepts as self-hatred and racial identity. Prior to departing to Accra, Ghana, students will be lead on a tour of the newly constructed Mississippi Civil Rights museum. While in Ghana, students will visit one of
        the original Slave Dungeons where enslaved Africans were held prior to being sold into
        bondage, as well as places such as the burial site for W.E.B. DuBois.
      </p>
    </div>
  </section>
  <section class="img-section">
    <div class="img-background">
      <img src="/assets/images/programs/program3.jpg" alt="">
    </div>
    <div class="text-wrap">
      <h3 class="title">About The City</h3>
      <p>
        Stretched along the Atlantic Ocean, Ghana's most populous city boasts glittering beaches, monumental buildings, museums, libraries, galleries, traditional markets and lively nightlife. Just three degrees north of the equator, students will find rainforests, wildlife parks, and pristine beaches ready for exploring.
        <br><br>Accra's architecture reflects its colonial history, with 17th century castles standing alongside modern skyscrapers. A veritable melting pot of cultures, the city's central financial and shopping districts contrast sharply with the less affluent residential areas surrounding the urban core.
      </p>
    </div>
  </section>
  <section class="text-section program-overview">
    <div class="text-wrap">
      <h3 class="title">Program Overview</h3>
      <p>
        Passport to the World takes place during the first summer term at JSU. Two weeks are spent on campus and two weeks abroad. Faculty members from Jackson State University will lead the program, including providing all instruction.
        <br><br>After completing this course students will be able to: conduct ethical research, master basic critical thinking skills, analyze primary and secondary source materials, develop a scholarly research paper with a defensible argument, supported by evidence and accurate citations.
      </p>
    </div>
    <div class="img-wrap">
      <img src="/assets/images/programs/program2.jpg" alt="">
    </div>
  </section>
  <section class="img-section cost">
    <div class="img-background">
      <img src="/assets/images/programs/program1.jpg" alt="">
    </div>
    <div class="text-wrap">
      <h3 class="title">Program Cost</h3>
      <p>
        Accommodations<br>
        Breakfast<br>
        Transportation (in country)<br>
        Excursions, admission fees, cultural experiences, etc.<br>
        iNext Comprehensive Travel Insurance<br>
        24/7 Emergency Assistance
      </p>
    </div>
  </section>
</div>
