import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor() { }

  getBackend(): string {
    let url: string;
    environment.backends.forEach(backend => {
      if (environment.deployTo === backend.name) url = backend.url;
    });
    return url;
  }
}
