import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/internal/operators";
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  baseUrl: string = '';
  offline: boolean = false;
  set isOffline(value: boolean) {
    this.offline = value;
  }

  get isOffline(): boolean {
    return this.offline;
  }

  constructor(private _http: HttpClient, private _sys: SystemService) {
    this.baseUrl = _sys.getBackend();
  }

  submitContactUs(contactus: any, token:string): Observable<any> {
    if (contactus) {
      let url: string;
      if (this.isOffline) {
        url = './api/contact_us.json';
        return this._http.get<any>(url).pipe(map(data => {
          return this.processResponse(data);
        }));
      } else {
        url = this.baseUrl + "/api/insert_contact_us.php";
        let request = {
          first_name: contactus.firstName,
          last_name: contactus.lastName,
          email: contactus.email,
          subject: contactus.subject,
          message: contactus.message,
          phone: contactus.phone,
          rechaptcha_response: token
        }
        return this._http.post<any>(url, request).pipe(map(data => {
          return this.processResponse(data);
        }));
      }
    } else {
      return null;
    }
  }

  getDestinations(): Observable<any> {
   
      let url: string;
      if (this.isOffline) {
        url = './api/destinations.json';
        return this._http.get<any>(url).pipe(map(data => {
          return this.processResponse(data);
        }));
      } else {
        url = './api/destinations.json';
        return this._http.get<any>(url).pipe(map(data => {
          return this.processResponse(data);
        }));

        //url = this.baseUrl + "/WS/getDestinations.php";
        //return this._http.post<any>(url, contactus).pipe(map(data => {
        //  return this.processResponse(data);
        //}));
      }
    
  }

  getPackages(): Observable<any> {

    let url: string;
    if (this.isOffline) {
      url = './api/packages.json';
      return this._http.get<any>(url).pipe(map(data => {
        return this.processResponse(data);
      }));
    } else {
      url = './api/packages.json';
      return this._http.get<any>(url).pipe(map(data => {
        return this.processResponse(data);
      }));

      //url = this.baseUrl + "/WS/getDestinations.php";
      //return this._http.post<any>(url, contactus).pipe(map(data => {
      //  return this.processResponse(data);
      //}));
    }

  }

  getDestinationById(id): Observable<any> {

    let url: string;
    if (this.isOffline) {
      switch (id) {
        case 1: //miami
          url = './api/destination_miami.json';
          break;
        case 2: //harlem
          url = './api/destination_harlem.json';
          break;
        case 3: //dominican republic
          url = './api/destination_dr.json';
          break;

      }
     
      return this._http.get<any>(url).pipe(map(data => {
        return this.processResponse(data);
      }));
    } else {
      switch (id) {
        case 1: //miami
          url = './api/destination_miami.json';
          break;
        case 2: //harlem
          url = './api/destination_harlem.json';
          break;
        case 3: //dominican republic
          url = './api/destination_dr.json';
          break;

      }
      return this._http.get<any>(url).pipe(map(data => {
        return this.processResponse(data);
      }));

      //url = this.baseUrl + "/WS/getDestinations.php";
      //return this._http.post<any>(url, contactus).pipe(map(data => {
      //  return this.processResponse(data);
      //}));
    }

  }


  private processResponse(response: any): any {
    if (response) {
      //console.log(response);
      if ((response.status_code && (+response.status_code === 200)) || (response.status && (+response.status === 200))) {

        return response;
      } else {
        throw new Error(response.message);
      }
    } else {
      throw new Error("No response returned");
    }


  }
}
