import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { ProgramsComponent } from './programs/programs.component';
import { StudyAbroadComponent } from './study-abroad/study-abroad.component';
import { PackagesComponent } from './packages/packages.component';


const routes: Routes = [
  {
  path: 'home',
  component: HomeComponent
},
{
  path: 'about',
  component: AboutComponent
},
{
  path: 'destination/:id',
  component: DestinationsComponent
},
{
  path: 'contact',
  component: ContactComponent
},
{
  path: 'volunteer',
  component: VolunteerComponent
},
{
  path: 'programs',
  component: ProgramsComponent
},
{
  path: 'study',
  component: StudyAbroadComponent
},
{
  path: 'package',
  component: PackagesComponent
},
{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full'
},
{
  path: '**',
  component: HomeComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
