<header>
  <!-- Top bar -->
  <nav id="top-nav" class="top-nav">
    <img class="logo" src="/assets/images/logo.png" alt="" />
    <div class="contact">
      <p>
        <span class="material-icons">call</span>
        <span>1-(844) 424-8404</span>
      </p>
      <p>
        <span class="material-icons">location_on </span>
        <span>PO BOX 1002 NEW YORK, NY 10025</span>
      </p>
    </div>
    <span class="material-icons mobile-menu" (click)="openSidebar = !openSidebar">menu</span>
  </nav>
  <!-- Primary Nav -->
  <nav class="primary-nav">
    <div class="web-nav">
      <button class="nav-item" routerLink="/home" routerLinkActive="active">HOME</button>
      <div class="dropdown">
        <button class="nav-item dropbtn" routerLink="" (click)="gotoLink(0)">
          DESTINATIONS
          <span class="material-icons">expand_more</span>
        </button>
        <div class="dropdown-content">
          <a routerLink="" (click)="gotoLink(1)">Miami</a>
          <a routerLink="" (click)="gotoLink(2)">Harlem</a>
          <a routerLink="" (click)="gotoLink(3)">Dominican Republic</a>
        </div>
      </div>
      <button class="nav-item" routerLink="/package" routerLinkActive="active">PACKAGES</button>
      <div class="dropdown">
        <button class="nav-item dropbtn" [class.active]="volunteerActive.isActive || programsActive.isActive || studyActive.isActive">
          <span>EVENT</span>
          <span class="material-icons">expand_more</span>
        </button>
        <div class="dropdown-content">
          <a routerLink="/volunteer" routerLinkActive="dropdown-active" #volunteerActive="routerLinkActive">Volunteer</a>
          <a routerLink="/programs" routerLinkActive="dropdown-active" #programsActive="routerLinkActive">Programs</a>
          <a routerLink="/study" routerLinkActive="dropdown-active" #studyActive="routerLinkActive">Study abroad</a>
        </div>
      </div>
      <button class="nav-item" routerLink="/about" routerLinkActive="active">ABOUT US</button>
      <button class="nav-item" routerLink="/contact" routerLinkActive="active">CONTACT US</button>
    </div>
  </nav>
  <!-- Mobile side nav -->
  <div class="side-nav" [ngClass]="openSidebar ? 'open' : 'close'">
    <mat-nav-list>
      <a mat-list-item routerLink="/home"  (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">home</span>
        Home
      </a>
      <a mat-list-item routerLink="/destination/0" (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">luggage</span>
        Destinations
      </a>
      <a mat-list-item routerLink="/package"  (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">local_activity</span>
        Packages
      </a>
      <a mat-list-item routerLink="/volunteer" (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">school</span>
        Volunteer
      </a>
      <a mat-list-item routerLink="/about"  (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">people_alt</span>
        About us
      </a>
      <a mat-list-item routerLink="/contact"  (click)="openSidebar = !openSidebar" disableRipple="true">
        <span class="material-icons menu-icon">contact_mail</span>
        Contact us
      </a>
    </mat-nav-list>
  </div>
</header>
<!-- Mobile side nav -->
<!-- <mat-sidenav-container class="side-nav">
  <mat-sidenav #sidenav position="end">
    <mat-nav-list>
      <a mat-list-item routerLink="/home" (click)="sidenav.toggle()" disableRipple="true">
        <span class="material-icons menu-icon">home</span>
        Home
      </a>
      <a mat-list-item (click)="sidenav.toggle()" disableRipple="true">
        <span class="material-icons menu-icon">luggage</span>
        Destinations
      </a>
      <a mat-list-item (click)="sidenav.toggle()" disableRipple="true">
        <span class="material-icons menu-icon">local_activity</span>
        Packages
      </a>
      <a mat-list-item routerLink="/about" (click)="sidenav.toggle()" disableRipple="true">
        <span class="material-icons menu-icon">people_alt</span>
        About us
      </a>
      <a mat-list-item routerLink="/contact" (click)="sidenav.toggle()" disableRipple="true">
        <span class="material-icons menu-icon">contact_mail</span>
        Contact us
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->
