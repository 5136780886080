<div class="container">
  <!--to show the destination summary-->
  <div class="main-container" *ngIf="destination_id==0">
    <!--show titles-->
    <div class="title-container">
        <h1>I'm going on an adventure</h1>
        <h2>Find essential information for your next journey</h2>
    </div>
    <div class="destination-container">
      <h2>Your destination</h2>
      <div class="card-container">
        <div class="card-strip">
          <a [routerLink]="" (click)="goToLink(item.id)" class="destination-card img-background" [ngStyle]="{'background-image': 'url(../../assets/images/destinations/'+ item.image+')','background-position':'center center','background-size':'cover','background-repeat': 'no-repeat'}" *ngFor="let item of destinations">
            <span class="card-place-category">{{item.state}}</span>
            <div class="card-description">
              <h3 class="place-name">{{item.destination}}</h3>
              <p class="place-tagline">{{item.short_desc}}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-container" *ngIf="destination_id!=0 && destination!=null">
    <span class='material-icons arrow-back' (click)="returnBack()" matTooltip="Back to Destination">arrow_back</span>
    <div class="title-container">
      <h1>{{destination.name}}</h1>
      <p>{{destination.introduction}}</p>
    </div>
    <div class="imageinstead fade-in one" [ngStyle]="{'background-image': 'url(../../assets/images/destinations/'+ destination.main_image+')','background-position':'center center','background-size':'cover','background-repeat': 'no-repeat'}"></div>
    <div class="know-container">
      <h2>Things to know</h2>
      <div class="image-container">
        <div class="image-item">
          <img [src]="getImage(destination.desc_img)" class="to-see-card" (click)="showDetail()" />
          <!--<a [routerLink]="" class="to-see-card" (click)="showDetail()" [ngStyle]="{'background-image': 'url(../../assets/images/destinations/'+ destination.desc_img+')','background-position':'center center','background-size':'cover','background-repeat': 'no-repeat'}"></a>-->
        </div>

        <div class="image-item">
          <img [src]="getImage(destination.topic_image1)" class="to-see-card" (click)="showDetail1()" />
          <!--<a [routerLink]="" class="to-see-card" (click)="showDetail1()" [ngStyle]="{'background-image': 'url(../../assets/images/destinations/'+ destination.topic_image1+')','background-position':'center center','background-size':'cover','background-repeat': 'no-repeat'}"></a>-->
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="overall-detail1" *ngIf="isDetail==true">
      <div class="modal-container">
        <a [routerLink]="" class="detail-close" (click)="closeDetail()">
          <mat-icon>close</mat-icon>
        </a>
        <div class="info-container">
          <div class="detail-item">
            <h3 class="detail-headline">{{destination.topic}}</h3>
            <p class="detail-snippet" [innerHTML]="destination.description"></p>
          </div>
          <div class="image-item">
            <img [src]="getImage(destination.desc_img)" class="to-see-card" />
          </div>
        </div>
      </div>
    </div>

    <div class="overall-detail1" *ngIf="isDetail1==true">
      <div class="modal-container">
        <a [routerLink]="" class="detail-close" (click)="closeDetail1()">
          <mat-icon>close</mat-icon>
        </a>
        <div class="info-container">
          <div class="detail-item">
            <h3 class="detail-headline">{{destination.topic1}}</h3>
            <p class="detail-snippet" [innerHTML]="destination.topic_desc1"></p>
          </div>
          <div class="image-item">
            <img [src]="getImage(destination.topic_image1)" class="to-see-card" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
