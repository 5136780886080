import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { AlertService } from '../services/alert.service';
import { UserApiService } from '../services/user-api.service';
import { takeUntil } from 'rxjs/internal/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ReCaptchaV3Service]
})
export class ContactComponent implements OnInit, OnDestroy {
  qaForm: FormGroup;
  isSubmitted: boolean = false;
  disabled: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private fb: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _backendService: UserApiService,
    private _loader: LoaderService,
    private alertService: AlertService)
  {
    window.scrollTo(0, 0);
    this.qaForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sendMsg(): void {
    console.log(this.qaForm.valid);

    if (this.qaForm.valid) {
      this.disabled = true;
      this.alertService.clear();
      this._loader.display(true);

      this.recaptchaV3Service.execute('contactUs').pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((token) => {
          console.log(token);
          this._backendService.submitContactUs(this.qaForm.value,token).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
              this.isSubmitted = true;
            }, (error: any) => {
              let msg = error.message ? error.message : error;
              this.alertService.error(msg);
              this._loader.display(false);
              this.disabled = false;
            }, () => {
              this._loader.display(false);
              this.disabled = false;
            });
        })
      //console.log(this.loan_request);
     

    }
  }

}
