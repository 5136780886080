import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'sbih-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  openSidebar: boolean;

  constructor(private router: Router, public breakpointObserver: BreakpointObserver) {
   
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY && window.innerWidth > 768) {
      document.getElementById('top-nav').classList.add("hide");
    } else {
      document.getElementById('top-nav').classList.remove("hide");
    }
  }

  ngOnInit(): void {
    
  }

  gotoLink(id): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/destination', id]));
    this.openSidebar = !this.openSidebar
  }

}
