<div id="home">
    <div class="top-background">
      <mat-carousel
        class="home-carousel"
        timings="250ms ease-in"
        [autoplay]="true"
        interval="5000"
        color="accent"
        maxWidth="auto"
        proportion="65"
        slides="5"
        [loop]="true"
        [hideArrows]="false"
        [hideIndicators]="false"
        [useKeyboard]="true"
        [useMouseWheel]="false"
        orientation="ltr">
      <mat-carousel-slide
        #matCarouselSlide
        *ngFor="let slide of slides; let i = index"
        [image]="slide.image"
        overlayColor="#00000040"
        [hideOverlay]="false"></mat-carousel-slide>
      </mat-carousel>
    </div>
    <div class="header-content">
      <h1 class="header-title">A LIFE CHANGING EXPERIENCE</h1>
      <h2 class="sub">Spring Break In Haiti sets a whole new standard</h2>
    </div>
    <div class="more-container">
      <h2 class="more-title"></h2>
      <div class="img-container">
        <img src="/assets/images/home/gallery-01.jpg" alt="">
        <img src="/assets/images/home/gallery-02.jpg" alt="">
        <img src="/assets/images/home/gallery-03.jpg" alt="">
        <img src="/assets/images/home/gallery-04.jpg" alt="">
        <img src="/assets/images/home/gallery-05.jpg" alt="">
        <img src="/assets/images/home/gallery-06.jpg" alt="">
        <img src="/assets/images/home/gallery-07.jpg" alt="">
        <img src="/assets/images/home/gallery-08.jpg" alt="">
      </div>
      <div class="foot-text">
        <h3 class="foot-title">Popular Destinations: </h3>
        <P>
          Cap Haitien, Ile-a-Vache, Jacmel, Les Cayes, Port -au-Prince and Port Salut, St. Marc
        </P>
      </div>
    </div>
    <div class="home-discover">
      <h3 class="discover-title">Discover Spring Break in Haiti</h3>
      <p>
        <strong>Spring Break in Haiti</strong> is a phenomenon, a voyage into the heart of the West Indies designed to cater to patrons, students, and socialites across the globe as they embark upon this peaceful, purified, and spiritual adventure into <span class="highlight">"The Pearl of the Caribbean"</span> during the spring break recess.
      </p>
      <p>
        Unlike what "Spring Break" is typically known for, Spring Break In Haiti sets a whole new standard. We don't just cater to the party-goers, SBIH services higher education, faculty, students, professionals and lovers of the Caribbean, who want to utilize their break by experiencing a cultural and enlightening journey.
      </p>
    </div>
    <div class="customer-review">
      <div class="review-content">
        <h3 class="review-title">What our customers say</h3>
        <div class="review-container">
          <!-- <h4>ADAMFOR</h4> -->
          <p class="review-message">
            <img src="../../../assets/images/left-quote.svg" alt="">
            <span>&nbsp;The history of this country is like no other, the people are warm and welcoming and the food is out of this world This Haiti trip changed my perspective on life completely. I can't wait to go back.</span>
          </p>
          <i>-Mark Anthony Sanders, Savannah State University  March 2016</i>
          <span class="rate-container">
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_half</span>
          </span>
        </div>
        <div class="review-container">
          <!-- <h4>CHAMBOWAMBO</h4> -->
          <p class="review-message">
            <img src="../../../assets/images/left-quote.svg" alt="">
            <span>&nbsp;Three words...Life changing experience...</span>
          </p>
          <i>- Mrs.Vera Culberson, Study Abroad Department, Lincoln University-MO  April 2018 </i>
          <span class="rate-container">
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
          </span>
        </div>
        <div class="review-container">
          <!-- <h4>SAKIBMOHSIN86</h4> -->
          <p class="review-message">
            <img src="../../../assets/images/left-quote.svg" alt="">
            <span>&nbsp;This year was very unique, COVID-19 just hit, and the CDC banned all International Spring break travels. We did not want to cancel, so in order to give us the experience, Mr. Gregory quickly transitioned our itinerary last minute to Miami, Florida. My very first time in the south. We toured the different parts of the city; Downtown, Overtown, Little Havana and spent a few days in Little Haiti. A great introduction to Haiti, a cultural experience by far, but I still want to go to Haiti.</span>
          </p>
          <i>-William B., Harris-Stowe State University  March 2020</i>
          <span class="rate-container">
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
            <span class="material-icons checked">star_rate</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  
