<div id="contact">
  <div class="top-background">
    <img src="../../../assets/images/contact/red_shirt.jpg" alt="">
  </div>
  <div class="contact-head">
    <div class="head-title">
      {{'contact_us' | translate}}
    </div>
  </div>
  <section class="info-container">
    <div id="contact-body">
      
      <div id="message-form" *ngIf="!isSubmitted">
        <app-alert></app-alert>
        <br />
        <app-loader></app-loader>
        <!-- <h3>FORM</h3> -->
        <form [formGroup]="qaForm" #formRef="ngForm" (ngSubmit)="sendMsg()" novalidate>
          <div class="require-right"><i><span class="color-danger">*</span>{{'required_label'|translate}}</i></div>
          <div class="col-two">
            <div class="input-box">
              <span>{{'first_name' | translate}}<span class="color-danger">*</span></span><br />
              <input type="text" class="form-control" formControlName="firstName" [class.input-error]="qaForm.controls.firstName?.invalid && formRef.submitted" />
              <span class="color-danger" *ngIf="qaForm.controls.firstName?.invalid && formRef.submitted">{{'firstname_require' | translate}}</span>
            </div>
            <div class="input-box">
              <span>{{'last_name' | translate}}<span class="color-danger">*</span></span><br />
              <input type="text" class="form-control" formControlName="lastName" [class.input-error]="qaForm.controls.lastName?.invalid && formRef.submitted" />
              <span class="color-danger" *ngIf="qaForm.controls.lastName?.invalid && formRef.submitted">{{'lastname_require' | translate}}</span>
            </div>
          </div>
          <div class="input-box">
            <span>{{'phone_label' | translate}}</span><br />
            <input type="tel" class="form-control" formControlName="phone" />
          </div>
          <div class="input-box">
            <span>{{'email_label' | translate}}<span class="color-danger">*</span></span><br />
            <input type="email" class="form-control" formControlName="email" [class.input-error]="qaForm.controls.email?.invalid && formRef.submitted" />
            <span class="color-danger" *ngIf="qaForm.controls.email?.errors?.required && formRef.submitted">{{'email_required' | translate}}</span>
            <span class="color-danger" *ngIf="qaForm.controls.email?.errors?.email && formRef.submitted">{{'valid_email' | translate}}</span>
          </div>
          <div class="input-box">
            <span>{{'subject_label' | translate}}<span class="color-danger">*</span></span><br />
            <input type="text" class="form-control" formControlName="subject" [class.input-error]="qaForm.controls.subject?.invalid && formRef.submitted" />
            <span class="color-danger" *ngIf="qaForm.controls.subject?.invalid && formRef.submitted">{{'subject_required' | translate}}</span>
          </div>
          <div class="input-box">
            <span>{{'message_label' | translate}}<span class="color-danger">*</span></span><br />
            <textarea class="form-control" formControlName="message" #message rows="7" [class.input-error]="qaForm.controls.message?.invalid && formRef.submitted" maxlength="250"></textarea>
            <mat-hint align="end">{{message.value.length}}/250</mat-hint>
            <span class="color-danger" *ngIf="qaForm.controls.message?.invalid && formRef.submitted">{{'msg_required' | translate}}</span>
          </div>
          <div style="text-align: center"><button type="submit" [disabled]="!qaForm.valid || disabled">{{'submit_label' | translate}}</button></div>
        </form>
      </div>
      <div *ngIf="isSubmitted">
        <div [innerHTML]="'contact_us_success'|translate"></div>
      </div>

      <div class="contact-info">
        <div class="info-box">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965781.8161926698!2d-73.6666681353234!3d18.99391711599476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb6c6f37fcbbb11%3A0xb51438b24c54f6d3!2sHaiti!5e0!3m2!1sen!2sus!4v1602981174786!5m2!1sen!2sus" frameborder="0"></iframe>        </div>
        <div class="detail-info">
          <p>
            <strong>{{'address_label' | translate}}:</strong>PO BOX 1002, NEW YORK, NY 10025
          </p>
          <p>
            <strong>{{'toll_free' | translate}}:</strong>1-(844)&nbsp;<i>HAITI</i>-04
          </p>
          <p>
            <strong style="color:white">{{'toll_free' | translate}}:</strong>1-(844)&nbsp;424-8404
          </p>
          <p>
            <strong>{{'email_label' | translate}}:</strong> <a href="mailto:springbreakinhaiti@gmail.com">springbreakinhaiti@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>




