import { Component, OnInit } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slides = [
    {'image': '/assets/images/home/slide-1.jpg'},
    {'image': '/assets/images/home/slide-2.jpg'},
    {'image': '/assets/images/home/slide-3.jpg'},
    {'image': '/assets/images/home/slide-4.jpg'},
    {'image': '/assets/images/home/slide-5.jpg'}
  ];

  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
  }

}
