import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule} from './core/core.module';
import { SharedModule} from './shared/shared.module';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { PackagesComponent } from './packages/packages.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderComponent } from './components/loader/loader.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { RecaptchaV3Module,RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ProgramsComponent } from './programs/programs.component';
import { StudyAbroadComponent } from './study-abroad/study-abroad.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    DestinationsComponent,
    PackagesComponent,
    ContactComponent,
    LoaderComponent,
    VolunteerComponent,
    ProgramsComponent,
    StudyAbroadComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatCarouselModule.forRoot()
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Lc5yB8aAAAAADPlTaJDXCGJaPwdsRXiF9Er27F8" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
