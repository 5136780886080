import { Component, OnInit } from '@angular/core';
import { BioData } from './about';
import { AboutService } from './about.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  bioData: BioData[];

  constructor(
    private aboutService: AboutService
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.getBioData();
  }

  getBioData(): void {
    this.aboutService.getBioData().subscribe(
      resp => {
        this.bioData = resp;
        this.bioData.forEach((data: BioData) => {
          if (data.description && data.description.length > 300) {
            data.showMore = true;
            const index = data.description.slice(0, 350).lastIndexOf(' ');
            data.shortDesc = data.description.slice(0, index);
          }
        })
      }
    );
  }

}
