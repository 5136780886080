<footer class="footer">
  <div class="links-container">
    <div class="links">
      <h4 class="title">TRAVEL</h4>
      <div>
        <p><a (click)="gotoLink(1)">Miami</a></p>
        <p><a (click)="gotoLink(2)">Harlem</a></p>
        <p><a (click)="gotoLink(3)">Dominican Republic</a></p>
        <p><a routerLink="/package">Packages</a></p>
      </div>
    </div>
    <div class="links">
      <h4 class="title">EVENT</h4>
      <p><a routerLink="/volunteer">Volunteer</a></p>
      <p><a routerLink="/volunteer">Programs</a></p>
      <p><a routerLink="/volunteer">Study abroad</a></p>
    </div>
    <div class="links">
      <h4 class="title">HELPFUL LINKS</h4>
      <div>
        <p><a routerLink="/contact">Contact us</a></p>
        <p><a routerLink="/about">About us</a></p>
      </div>
    </div>
    <div class="social">
      <h4 class="title">SOCIAL LINKS</h4>
      <a href="https://m.facebook.com/springbreakinhaiti/" target="_blank"><img src="../../../assets/images/facebook.svg" alt="Facebook"></a>
      <a href="https://www.instagram.com/spring_break_in_haiti/?hl=en" target="_blank"><img src="../../../assets/images/instagram.svg" alt="Instagram"></a>
      <a href="https://www.linkedin.com/in/spring-break-in-haiti-728b29a" target="_blank"><img src="../../../assets/images/linkedin.svg" alt="Linkedin"></a>
      <a href="https://mobile.twitter.com/springinhaiti" target="_blank"><img src="../../../assets/images/twitter.svg" alt="Twitter"></a>
    </div>
  </div>
  <div class="rights">
    <img class="logo" src="/assets/images/logo.png" alt="" />
    <small class="text">© All Rights Reserved</small>
  </div>
</footer>
